import { Link, useLocation} from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react'
import { RxTokens,RxExit,RxGear,RxDashboard  } from "react-icons/rx";
import { LuText, LuLayoutDashboard  } from "react-icons/lu";
import { GoDependabot, GoPeople, GoMegaphone } from "react-icons/go";
import { BsPatchExclamation } from "react-icons/bs";
import { IoPersonAddOutline } from "react-icons/io5";
import { FiActivity } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";

export const Sidebar = () => {
    const { signOut } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    
    const [activePage, setActivePage] = useState('');

    const handleSignOut = async () => {
        await signOut();
        navigate("/");
      };

      useEffect(() => {
        // Atualiza o estado da página ativa sempre que a localização muda
        setActivePage(location.pathname);
      }, [location.pathname]);

    return(
        <>
        <section className="min-h-screen w-20 hover:w-32 transition-all bg-black justify-center">
          <nav className="items-center flex flex-col content-between py-12">
            <ul>
              <li className='list-none p-2.5 mt-5 text-white rounded-sm'><Link to="/dashboard"><RxDashboard  size={23}/></Link></li>
              <li className='list-none p-2.5 mt-5 text-white rounded-sm'><Link to="/collaborator"><IoPersonAddOutline size={23}/></Link></li>
              <li className='list-none p-2.5 mt-5 text-white rounded-sm'><Link to="/denunces"><BsPatchExclamation size={23}/></Link></li>
            </ul>
            <button onClick={handleSignOut} className="text-white mx-auto border-none p-2 rounded-sm fixed top-3/4 mt-10 "><RxExit color="white" size={23}/></button>
          </nav>
        </section>
      </>
    )
}