import api from "../services/api";

export const createAdminUser = async (data) =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.post(`/admin/register`, data,{
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
          
      });
      return response
  } catch (error) {
      console.error('Erro ao criar conta:', error.request._response);
      console.log(error)
  }
}
export const getAllAdminUsers = async () =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.get(`/admin/users`, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
          
      });
      return response
  } catch (error) {
      console.error('Erro ao exibir usuários:', error.request._response);
      console.log(error)
  }
}

export const adminUserEdit = async (id, data) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.patch(`/admin/${id}/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        console.log(response.data)
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }

export const updateSubscription = async (id, data) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.put(`/admin/subscription/${id}`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        console.log(response.data)
        return response
    } catch (error) {
        console.error('Erro ao atualizar subscrição:', error.request._response);
        console.log(error)
    }
  }

export const getAdminSelected = async (id) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.get(`/admin/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
    }
  }

export const deleteAdminUser = async (id) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.delete(`/admin/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }