import api from "../services/api";

export const GetComplaints = async () =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.get(`/complaints`, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
      });
      console.log(JSON.parse(token))
      return response
  } catch (error) {
      console.error('Erro ao exibir denúncias:', error.request._response);
      console.log(error)
  }
}