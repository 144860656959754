import { Sidebar } from "./components/Sidebar";
import { AuthProvider } from "./context/Auth";
import { Routers } from "./routers/router";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <AuthProvider>
      <Toaster/>
      <Routers/>
    </AuthProvider>
  );
}

export default App;
