import React, {useState, useEffect, useContext} from "react";
import { Sidebar } from "../../components/Sidebar";
import { AuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt, FaRegEye } from "react-icons/fa";
import { MdOutlineOpenInNew } from "react-icons/md";
import { createAdminUser, deleteAdminUser, getAllAdminUsers, updateSubscription } from "../../business/AdminBusiness";
import { CiCircleRemove } from "react-icons/ci";
import { DeleteUser, GetAllUsers, imageUserDelete } from "../../business/UserBusiness";
import toast from "react-hot-toast";

export const Collaborator = () => {

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('admin');
    const navigate = useNavigate()
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [addAdmin, setAddAdmin] = useState(null);
    const [selectedDelete, setSelectedDelete] = useState(null)
    const [selectedPerson, setSelectedPerson] = useState(null)
    const [selectedDeleteUser, setSelectedDeleteUser] = useState(null)
    const [selectedUserId, setSelectedUserId] = useState(null)
  
    const { azureToken } = useContext(AuthContext);
    const token = azureToken?.split('"')?.join('')
  
    const adminName = localStorage.getItem("@Auth:name")
    const idLocal = localStorage.getItem("@Auth:Id")

    const id = idLocal?.split('"')?.join('')
  
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const response = await getAllAdminUsers();
        console.log(response);
        setUsers(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
  
  const confirmDeleteUser = async (userId) => {
    try {
        await deleteAdminUser(userId);      
        // Atualiza o estado de user.user.images para remover a imagem excluída
        toast.success('Conta administrativa removida com sucesso!');
        setSelectedPerson(null)
  
    } catch (error) {
        console.error('Erro ao excluir conta do usuário:', error);
        toast.error('Erro ao deletar conta administrativa')
    }
  
  };
  
    const filteredUsers = users?.filter((user) => 
      user?.name.toLowerCase().includes(searchTerm?.toLowerCase())
    )
  
    const handleSearchTerm = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handlePersonClick = (person) => {
      setSelectedPerson(person);
      setSelectedUserId(person._id)
    };
  
    const handleModalClose = () => {
      setSelectedPerson(null);
    };
  
    const formatCreatedAt = (createdAt) => {
      const createdAtDate = new Date(createdAt);
      return createdAtDate.toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };
  
  
    useEffect(() => {
      fetchUsers();
    }, []);

    const handleAddAdmin = async () => {
        try{
            const admin = {
                email: email,
                name: name,
                password: password,
                role: role
            }
            
            setIsLoading(true)

            await createAdminUser(admin)

            toast.success('Conta administrativa criada com sucesso!')
            
            setIsLoading(false)
            
            setAddAdmin(false)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.msg)
        }
    }

    return(
        <>
        <div className="flex flex-row">
            <Sidebar/>
            <div className="flex flex-col w-10/12 mx-auto py-10">
            <div className="flex flex-col w-10/12 mx-auto ">
              <h1 className="text-xl font-light leading-6">Olá, <span className="font-bold">{adminName}</span></h1>
              <p className="text-lg font-light leading-6 text-gray-700">Moderadores</p>
            </div>
            <div className="flex flex-col w-10/12 mx-auto py-10">
                <div className="flex flex-row justify-between">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Pesquise pelo nome ({filteredUsers.length} administradores)
                    </label>
                    <label
                        htmlFor="add"
                        className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer"
                        onClick={() => setAddAdmin(true)}
                    >
                        Adicionar administrador
                    </label>
                </div>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Digite um nome..."
                  onChange={handleSearchTerm}
                  value={searchTerm}
                />
              </div>
            </div>

            <table className="divide-y divide-gray-100 w-10/12 mx-auto">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cargo</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Criada em</th>
                        <th className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <div>
                            <p className="py-4 px-6">Carregando...</p>
                        </div>
                    ) : (
                        filteredUsers?.map((person) => (
                            <tr
                                key={person?.email}
                                className="hover:bg-gray-100 cursor-pointer"
                                onClick={() => handlePersonClick(person)}
                            >
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="text-sm font-medium text-gray-900">{person?.name}</div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">{person?.email}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person?.role === 'admin' ? 'Administrador(a)' : 'Nada'}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatCreatedAt(person?.createdAt)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <div className="text-gray-900 hover:text-indigo-600 cursor-pointer">
                                        <FaRegEye size={20} color="grey-500"/>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
        </div>

        {addAdmin === true ? (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
                <div className="justify-center w-2/5 mx-auto flex flex-col">
                    <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <CiCircleRemove 
                            onClick={() => setAddAdmin(false)}
                            size={28}
                            className="top-2 text-red-600 hover:text-red-800 mb-4 cursor-pointer"
                        >
                            Fechar
                        </CiCircleRemove>
                        <h2 className="text-xl mt-6 font-semibold mb-4">
                            Cadastrar administrador
                        </h2>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nome">
                                Nome
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="nome" type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)}/>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                Senha
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="repeatPassword">
                                Repetir senha
                            </label>
                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="repeatPassword" type="password" placeholder="******************" onChange={(e) => setRepeatPassword(e.target.value)}/>
                        </div>
                        <div className="mb-6">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                                Cargo
                            </label>
                            <select className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="role" onChange={(e) => setRole(e.target.value)}>
                                <option value="admin">Administrador(a)</option>
                            </select>
                        </div>
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-auto w-full mb-5" type="button" onClick={handleAddAdmin}>
                                {isLoading ? 'Criando...' : 'Criar'}
                            </button>
                    </form>
                </div>
            </div>
        ) : false}

        {selectedPerson && selectedUserId !== id &&  selectedUserId !== '663095d646b1156efc225568' ? (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
                    <div className="justify-center w-2/5 mx-auto flex flex-col">
                        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                            <CiCircleRemove 
                                onClick={() => setSelectedPerson(null)}
                                size={28}
                                className="top-2 text-red-600 hover:text-red-800 mb-4 cursor-pointer"
                            >
                                Fechar
                            </CiCircleRemove>
                            <h2 className="text-xl mt-6 font-semibold mb-4">
                                Deletar administrador
                            </h2>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-10" htmlFor="nome">
                                    Deseja deletar o administrador(a) {selectedPerson.name}?
                                </label>
                            </div>
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-auto w-full mb-5" type="button" onClick={() => confirmDeleteUser(selectedUserId)}>
                                    {isLoading ? 'Deletando...' : 'Deletar administrador'}
                                </button>
                                {console.log(selectedPerson)}
                        </form>
                    </div>
            </div>
        ) : null}

        </>
    )
}