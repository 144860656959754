import React, {useState, useEffect, useContext} from "react";
import { AuthContext } from "../../context/Auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const authContext = useContext(AuthContext);
    const { signIn, signed } = authContext;
    const navigate = useNavigate()

    const handleSignIn = async (e) => {
        e.preventDefault();
      
        // Verifica se o email ou senha está vazio
        if (!username || !password) {
          toast.error("Opa! Por favor, preencha o email e a senha.");
          return
        }
      
        console.log(username);
        const data = {
          email: username,
          password: password,
        };
      
        try {
          setIsLoading(true);
          await signIn(data);
        } catch (error) {
          const errorMessage = error.response?.data?.msg || "Ocorreu um erro ao fazer login.";
          console.log(errorMessage);
          toast.error(`Erro ao fazer login. ${errorMessage}`);
        } finally {
          setIsLoading(false);
        }
      };
  
      useEffect(() => {
        if (signed) {
            navigate('/dashboard')
        }
    }, [signed]);
  


    return(
        <div className="justify-center flex flex-col">
            <div className="w-full max-w-xs mx-auto py-20">
                <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" for="email">
                    Email
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" onChange={(e) => setUsername(e.target.value)}/>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
                    Senha
                    </label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={handleSignIn}>
                    {isLoading ? 'Entrando...' : 'Entrar'}
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}