import api from "../services/api";

export const GetAzureToken = async () =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.get(`/blob/sastoken`, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
          
      });
      return response
  } catch (error) {
      console.error('Erro ao gerar token:', error.request._response);
      console.log(error)
  }
}

export const CreateUser = async (data) =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.post(`/auth/register`, data,{
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data',
          },
          
      });
      return response
  } catch (error) {
      console.error('Erro ao criar conta:', error.request._response);
      console.log(error)
  }
}
export const GetAllUsers = async () =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.get(`/`, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
          
      });
      return response
  } catch (error) {
      console.error('Erro ao exibir usuários:', error.request._response);
      console.log(error)
  }
}

export const userEdit = async (id, data) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.patch(`/user/${id}/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        console.log(response.data)
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }

export const getUserSelected = async (id) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.get(`/user/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
    }
  }

export const imageUserEdit = async (id, data) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.put(`/images/user/${id}/`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }
export const userProfileImageEdit = async (id, data) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.put(`/user/${id}`, data, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }

export const imageUserDelete = async (id, imageId) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.delete(`/images/user/${id}/${imageId}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }

export const DeleteUser = async (id) =>{
    try {
        const token = localStorage.getItem("@Auth:token");
        const response = await api.delete(`/user/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
        });
        return response
    } catch (error) {
        console.error('Erro ao obter tarefas:', error.request._response);
        console.log(error)
    }
  }