import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PrivateRoute } from "./privateRoutes";
import { Login } from '../pages/Login';
import { Home } from '../pages/Home';
import { Collaborator } from '../pages/Collaborator';
import { Complaints } from '../pages/Complaints';

export const Routers = () =>{
  return(
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/dashboard" element={<PrivateRoute/>}>
        <Route element={<Home/>} path="/dashboard"/>
      </Route>
      <Route path="/collaborator" element={<PrivateRoute/>}>
        <Route element={<Collaborator/>} path="/collaborator"/>
      </Route>
      <Route path="/denunces" element={<PrivateRoute/>}>
        <Route element={<Complaints/>} path="/denunces"/>
      </Route>
    </Routes>
  )
}